import React from "react"
import { Spring } from "react-spring/renderprops"

function FactsNNumUl() {
  return (
    <ul className="factsNNumUl">
      <li>
        <div className="numNumder">
          <Spring
            from={{ number: 0 }}
            to={{ number: 71 }}
            config={{ precision: 0.1 }}
          >
            {props => <div>{props.number}</div>}
          </Spring>
          &#37;
        </div>
        <p className="numDesc">
          건물수명이 다 하기까지 인력, 유지보수
          <br />
          부대비용 비율 총지출액의 71&#37;
        </p>
      </li>
      <li>
        <div className="numNumder">
          &#36;
          <Spring
            from={{ number: 0 }}
            to={{ number: 5 }}
            config={{ precision: 0.1 }}
          >
            {props => <div>{props.number}</div>}
          </Spring>
          trillion
        </div>
        <p className="numDesc">
          2022년 까지 스마트시티테크로 인한
          <br />
          전세계의 도시 운영비용 매년 5조달러(5,000조원) 감소 예상
        </p>
      </li>
      <li>
        <div className="numNumder">
          <Spring
            from={{ number: 0 }}
            to={{ number: 30 }}
            config={{ precision: 0.1 }}
          >
            {props => <div>{props.number}</div>}
          </Spring>
          &#37;
        </div>
        <p className="numDesc">
          미 도시 내 스마트시티테크의 발전으로
          <br />
          한해 도시,건물 유지 보수 비용 30&#37; 감소
        </p>
      </li>
    </ul>
  )
}
export default FactsNNumUl
