import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { ParallaxProvider } from "react-skrollr"
//import range from 'lodash-es/range'

import SEO from "../layout/seo"
import FactsNNumUl from "./FactsNNumUl"

//import iotVideo from "./iotVideo"

import { MainVisualImg } from "./MainVisualImg"
import smart_0 from "../../img/smart_0.png"
import smart_1 from "../../img/smart_1.png"
import smart_2 from "../../img/smart_2.png"
import smart_3 from "../../img/smart_3.png"
import main_1 from "../../img/icons/main_01.png"
import main_2 from "../../img/icons/main_02.png"
import main_3 from "../../img/icons/main_03.png"

import classNames from "classnames"

function useScroll(position) {
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    const handler = () => {
      setScrolled(window.scrollY > position)
    }
    window.addEventListener("scroll", handler)
    return () => window.removeEventListener("scroll", handler)
  }, [position])

  return scrolled
}

function Visual() {
  const scrolled = useScroll(500)

  return (
    <ParallaxProvider
      init={{
        smoothScrollingDuration: 500,
        smoothScrolling: true,
        forceHeight: false,
      }}
    >
      <div>
        <SEO title="M2MTech" />
        <div className="mainWrap">
          <div className="sectionWrap mainVisual">
            <div className="backgroundAnimation">
              <p></p>
            </div>
            <div className="zIndex">
              <div className="imgWrap">
                <MainVisualImg />
              </div>
              <div className={classNames("textWrap", scrolled && "test")}>
                <h2>
                  ELSA AI IoT x ELSA.Cloud
                  <span className="HeadLKor">
                    AI 기기와 디지털 산업 애플리케이션을 연결 및 확장하여 최적의
                    효율과 결과를 얻으세요.
                  </span>
                </h2>
                <p className="bodyText">
                  (주)엠투엠테크의 엘사(ELSA)솔루션을 통해 로컬현장과 클라우드
                  센터를 연결하는 지능형 IoT gateway를 통하여 각 노드 간 통신,
                  보안 등 최신 국제 IoT표준, 웹 보안 표준을 적용한 고품질의
                  안전한 사물인터넷 서비스 환경을 제공합니다. 대규모 현장은 물론
                  소규모 현장에서도 탄력적 서버운용 및 사용하는 만큼 과금하므로
                  효율적인 비용 감축이 가능합니다.
                </p>
              </div>
            </div>
            <p className="scrollDown">
              <span>READ MORE</span>
              <br />
              <svg height="11" width="15">
                <polygon points="0,0 15,0 7.5,11" className="triangle" />
              </svg>
            </p>
          </div>
          <div className="sectionWrap factsNNum">
            <FactsNNumUl />
            <div className="facts">
              <p className="factsCite">
                | 'Smart Cities and Cost Savings' Report from ABI Research, US
              </p>
              사람들이 건물을 사용하는 방식은 끊임없이 변화하고 있지만 건물과
              도시는 잘 설계된 장기 자산으로 한번 세우면 변화가 쉽지 않습니다.
              인지적 컴퓨팅으로 구동되는 IoT를 통한 혁신적인 기술로 장기간
              지출되는 운영비용을 획기적으로 줄이고 사용자의 경험을 향상시킬 수
              있습니다.
            </div>
            <ul className="whatWeOffer">
              <li>
                <p className="imgWrap">
                  <img src={main_1} alt="" />
                </p>
                <p className="titleWrap">비용절감</p>
                <p className="listWrap">
                  <span>에너지 절감</span>
                  <span> 고장 원격대응 </span>
                  <span>노후화 예상/수명관리 </span>
                  <span>인력 효율</span>
                  <span>대형사고 예방 </span>
                  <span>합리적인 과금</span>
                </p>
              </li>
              <li>
                <p className="imgWrap">
                  <img src={main_2} alt="" />
                </p>
                <p className="titleWrap">신속과 정확도</p>
                <p className="listWrap">
                  <span>보안과 경비</span>
                  <span>실시간 소음/진동모니터링</span>
                  <span>스마트폰 알림</span>
                  <span>비상상황예측</span>
                  <span>자금순환관리</span>
                  <span>기기, 빌딩 보수 유지</span>
                </p>
              </li>
              <li>
                <p className="imgWrap">
                  <img src={main_3} alt="" />
                </p>
                <p className="titleWrap">효율과 안전</p>
                <p className="listWrap">
                  <span>커스텀운영</span>
                  <span>기록, 통계, 보고 자동화</span>
                  <span>영상 리코딩&amp;리플레이</span>
                  <span>pc 모바일기기 엑세스</span>
                  <span>데이터 처리 보안</span>
                  <span>공간효율</span>
                </p>
              </li>
            </ul>
          </div>
          <div className="sectionWrap elsaSmart">
            <div className="backgroundAnimation">
              <p></p>
            </div>
            <div className="zIndex">
              <div className="textWrap">
                <h2>스마트홈, 스마트 빌딩, 스마트시티</h2>
                <p className="bodyText">
                  엘사솔루션은 대부분의 산업 전반에서 기계와 사람이 소통하는
                  통로로서 역할합니다. 인공지능 AI, 클라우드, 웹/앱 PC/모바일
                  플랫폼 등의 최신 원격감시 기술을 적용하고 높은 속도의 대용량
                  데이터처리, 노후화, 상태 오류체크 모니터링을 실시간으로
                  가능하도록 하고 기업의 효율적인 이윤창출과 인력관리가
                  가능하도록 종합업무자동화시스템(ERP) 를 융합하였습니다.
                </p>
              </div>
              <ul className="ul01">
                <li>
                  <p className="imgWrap">
                    <img src={smart_0} alt="" />
                  </p>
                  에스컬레이터
                </li>
                <li>
                  <p className="imgWrap">
                    <img src={smart_1} alt="" />
                  </p>
                  승강기
                </li>
                <li>
                  <p className="imgWrap">
                    <img src={smart_2} alt="" />
                  </p>
                  기계식/타워 주차장
                </li>
                <li>
                  <p className="imgWrap">
                    <img src={smart_3} alt="" />
                  </p>
                  물류센터
                </li>
              </ul>
              <ul className="ul02">
                <li>보안, 경호 </li>
                <li>스마트 벤딩머신</li>
                <li>스마트 팩토리</li>
                <li>보안 / 감시</li>
                <li>ICS/SCADA</li>
                <li>스마트 홈</li>s<li>스마트 시티</li>
              </ul>
            </div>
            <p className="moreBtn">
              <Link to="/Elsa/">LEARN MORE ABOUT ELSA</Link>
            </p>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  )
}

export default Visual
